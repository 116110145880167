<template>
    <v-layout justify-center>
        <v-flex xs12 sm8 md4>
            <v-stepper v-model="stepNo">
                <v-stepper-header>
                    <template v-for="(title, index) in titles">
                        <v-stepper-step :complete="stepNo > index + 1" :step="index + 1" :key="'step'+index">{{title}}</v-stepper-step>
                        <v-divider v-if="index !== titles.length - 1" :key="'devider'+index"></v-divider>
                    </template>
                </v-stepper-header>
                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card-text>
                            <v-form ref="form" v-model="valid">
                                <v-text-field
                                    outline
                                    prepend-icon="person"
                                    name="username"
                                    :label="$t('forgotPassword.account')"
                                    type="text"
                                    v-model="username"
                                    :rules="rules"
                                ></v-text-field>
                            </v-form>
                            <v-btn large block class="mt-0" color="primary" @click="submit">{{ $t('forgotPassword.send') }}</v-btn>
                        </v-card-text>
                    </v-stepper-content>
                    <v-stepper-content step="2" class="pa-1">
                        <change-password
                            :pass="authcode"
                            @back="setStepNo(1)"
                        ></change-password>
                    </v-stepper-content>
                </v-stepper-items>
                <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err"></waiting-dialog>
                <message-dialog
                    :show.sync="msgDialog.show"
                    :msg="msgDialog.msg"
                    :primary-btn="msgDialog.primaryBtn"
                    @primary="keep"
                ></message-dialog>
            </v-stepper>
        </v-flex>
    </v-layout>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import { HostUrl, SideBtnType } from '@/store'
import { getAxiosConfig, PWA_TOKEN } from '@/utils/AuthService'
import ChangePassword from '@/views/ChangePassword.vue'
import WaitingDialog from '@/components/WaitingDialog.vue'
import MessageDialog from '@/components/MessageDialog.vue'
import WaitDialogControl from '@/mixins/WaitDialogControl.vue'
import MessageDialogControl from '@/mixins/MessageDialogControl.vue'
import i18n from '@/i18n'

export default {
    name: 'ForegetPassword',
    components: { ChangePassword, WaitingDialog, MessageDialog },
    mixins: [WaitDialogControl, MessageDialogControl],
    data() {
        return {
            titles: [ i18n.t('forgotPassword.forget-name'), i18n.t('forgotPassword.forget-content')],
            stepNo: 1,
            valid: false,
            username: '',
            routepage: 0,
            rules: [
                v => !!v || i18n.t('forgotPassword.usernameRules'),
                v => ( (/^09\d{8}$/.test(v) && v.length === 10) || /.+@.+/.test(v) ) || i18n.t('forgotPassword.correct-format')
            ]
        };
    },
    computed: {
        authcode() {
            if (this.stepNo === 2)
                return 'authcode'
            else return null
        }
    },
    methods: {
        init() {
            this.stepNo = 1
            this.username = ''
            this.routepage = 0
            this.valid = false
        },
        setStepNo(no) {
            this.stepNo = no
            store.commit('setTitle', this.titles[this.stepNo - 1])
            if(no === 1) this.routepage = 0
            this.closeDialog()
        },
        submit() {
            if (this.$refs.form.validate()) {
                this.resetPass();
            }
            else {
                store.commit('setSnackBar', {
                    message: i18n.t('forgotPassword.mobile-format-incorrect'),
                    color: 'error'
                })
            }
        },
        keep() {
            if (this.routepage === 1) {
                this.setStepNo(2)
            } else {
                this.closeMsgDialog()
                this.closeDialog()
            }
        },
        exit() {
            this.init()
        },
        async resetPass() {
            this.showDialog(i18n.t('forgotPassword.verify-phone-number'), i18n.t('forgotPassword.sms-failed'))
            let btnmsg = i18n.t('forgotPassword.close')
            this.routepage = 0
            try {
                var suburl = '/api/user/password/forget';
                let response = await axios.post(HostUrl + suburl, {
                    username: this.username
                }, getAxiosConfig(PWA_TOKEN));
                //response.data.message = "已將修改密碼認證碼發送至您的手機"//"lose username"//"超過發送次數上限, 請於 30 分鐘後再嘗試" //"此帳號不存在" //"已將修改密碼認證碼發送至您的手機"
                console.log(response.data.message)
                if(response.data.message === "已將修改密碼認證碼發送至您的手機") {
                    response.data.message = i18n.t('forgotPassword.message-box.forget-auth-sent')
                    btnmsg = i18n.t('forgotPassword.message-box.type-auth-info-sms')
                    this.routepage = 1
                } else if(response.data.message === "已將修改密碼認證碼發送至您的電子信箱") {
                    response.data.message = i18n.t('forgotPassword.message-box.forget-auth-mail')
                    btnmsg = i18n.t('forgotPassword.message-box.type-auth-info-mail')
                    this.routepage = 1
                } else if(response.data.message === '超過發送次數上限, 請於 30 分鐘後再嘗試') {
                    response.data.message = i18n.t('forgotPassword.message-box.exceeded-sending-limit')
                    btnmsg = i18n.t('forgotPassword.other-login-method')
                    this.routepage = -1
                } else if(response.data.message === "此帳號不存在") {
                    response.data.message = i18n.t('forgotPassword.message-box.account-not-exist')
                } else {
                    response.data.message = i18n.t('forgotPassword.message-box.be-register')
                    this.routepage = -2
                }
                this.showMsgDialog(response.data.message, btnmsg)
            } catch (error) {
                this.showMsgDialog(i18n.t('forgotPassword.message-box.forgot-pass-unusual'), i18n.t('forgotPassword.message-box.back-to-login-page'))
            } finally {
                this.closeDialog()
                console.log('rpp:'+this.routepage)
                if (this.routepage < 0) {
                    this.exit()
                    this.$router.push(`/${i18n.locale}/login/`)
                }
            }
        }
    },
    activated() {
        store.commit('setTitle', 'forgotPassword.nav-forgotPass');
        store.commit('setSideBtnType', SideBtnType.Back);
    },
    deactivated() {
        console.log('forgot pass deactive')
        this.exit()
        store.commit('setSideBtnType', SideBtnType.Navi);
    }
};
</script>

<style scoped>
</style>
